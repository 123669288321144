import { add as auditApi } from "@/api/common/approval";
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            selections: [],
            selectionText: "",
            checkedAll: false,
            showAudit: false,
            spIdField: "approvalStatus",
            auditLoading1: false,
            auditLoading2: false,
            auditLoading3: false,
            auditDisabled1: false,
            auditDisabled2: false,
            auditDisabled3: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            roles: 'roles',
            token: 'token'
        }),
    },
    created() {
    },
    methods: {
        // 多选选中
        checkHandel(row) {
            if (row.checked) {
                this.$set(row, "checked", false);
                this.selections.forEach((element, index) => {
                    if (row.id === element.id) {
                        this.selections.splice(index, 1);
                    }
                });
            } else {
                this.$set(row, "checked", true);
                this.selections.push(row);
            }
            if (this.selections.length > 0) {
                this.selectionText =
                    "已选择 <span>" + this.selections.length + " 条</span>";
            } else {
                this.selectionText = "";
            }
            if (this.data.length === this.selections.length) {
                this.checkedAll = true
            } else if (this.selections.length === 0) {
                this.checkedAll = false
            }
        },
        auditApi,
        toCloseAudit(show) {
            this.auditText = "";
            this.showAudit = false;
            this.auditLoading1 = false
            this.auditLoading2 = false
            this.auditLoading3 = false
            this.auditDisabled1 = false
            this.auditDisabled2 = false
            this.auditDisabled3 = false
            this.selections = [];
            this.selectionText = "";
            this.data.map((elment) => {
                elment["checked"] = false;
                return elment;
            });
            if (show) {
                this.$notify({
                    type: "success",
                    message: "审批成功",
                    duration: 2000,
                });
            }
            this.toQuery();
        },
        selcetAll() {
            // let status = this.convertLevel();
            if (this.checkedAll) {
                this.selections = this.data
                    // .filter((e) => status === parseInt(eval("e." + this.spIdField)))
                    .map((elment) => {
                        elment["checked"] = true;
                        return elment;
                    });
                this.selectionText =
                    "已选择:<span>" + this.selections.length + "条</span>";
            } else {
                this.selections = this.data.map((elment) => {
                    elment["checked"] = false;
                    return elment;
                });
                this.selections = [];
                this.selectionText = "";
                this.checkedAll = false
            }
        },
        // 处理审核角色
        convertLevel() {
            let arr = 0
            let roleKey = this.roles.map((item) => {
                return item.roleKey;
            });
            // 辅导员
            if (roleKey.indexOf("headTeacher") > -1) {
                arr = 1
                //学院
            } else if (roleKey.indexOf("college") > -1) {
                arr = 2
                // 学生处
            } else if (roleKey.indexOf("manager") > -1) {
                arr = 3
            }
            return arr;
        },
        batchAudit() {
            if (this.selections.length > 0) {
                this.showAudit = true;
            } else {
                this.$notify({
                    type: "warning",
                    message: "请至少选择一条数据操作！",
                    duration: 1500,
                });
            }
        },
        toStuDeatil(data) {
            this.$router.push({
                path: "/js/stu/detail",
                query: { stu: btoa(data.stuNo) },
            });
        },
        disableBtn(spzt, type) {
            let status = parseInt(spzt);
            if ([9].indexOf(status) > -1) {
                return true;
            }
            if (type === "del") {
                if ([2, 3, 4, 5].indexOf(status) > -1) {
                    return true;
                }
            } else if (type === "edit") {
                if ([101, 201, 301, 2, 3].indexOf(status) > -1) {
                    return true;
                }
            }
            return false;
        },
        decrypt(str) {
            return atob(str);
        },
        // 审批状态 返回label值
        getSpztLabel(shzt) {
            let approvalStatus = parseInt(shzt);
            return this.dict.xj_shzt.filter(item => {
                return parseInt(item.value) === approvalStatus
            }).map(item => { return item.label }).toString()
        },
        loadingStatus(type) {
            if (type === 8) {
                this.auditLoading1 = true
                this.auditLoading2 = false
                this.auditLoading3 = false
                this.auditDisabled1 = false
                this.auditDisabled2 = true
                this.auditDisabled3 = true
            } else if (type === 9) {
                this.auditLoading1 = false
                this.auditLoading2 = true
                this.auditLoading3 = false
                this.auditDisabled1 = true
                this.auditDisabled2 = false
                this.auditDisabled3 = true
            } else if (type === 1) {
                this.auditLoading1 = false
                this.auditLoading2 = false
                this.auditLoading3 = true
                this.auditDisabled1 = true
                this.auditDisabled2 = true
                this.auditDisabled3 = false
            }
        }
    }
}
