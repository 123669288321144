import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/jz/tkszxj',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/jz/tkszxj/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/jz/tkszxj',
    method: 'put',
    data
  })
}

export function getTkszxjById(params) {
  return request({
    url: 'api/jz/tkszxj/tkszxjById',
    method: 'get',
    params
  })
}

export function save(data) {
  return request({
    url: 'api/jz/tkszxj/tkszxjSave',
    method: 'put',
    data
  })
}

export function downloadPdf(id) {
  return request({
    url: 'api/jz/tkszxj/pdf/' + id,
    method: 'get',
    responseType: 'blob'
  })
}

export default { add, edit, del ,downloadPdf}

